<template>
  <div class="p-5">
    <div class="mb-5 d-flex justify-space-between">
      <div>
        <button
          class="btn btn-secondary"
          @click.left="back"
          @click.middle="back"
        >
          {{ "Zurück zum Dashboard" }}
        </button>
      </div>
      <div>
        <button class="btn btn-primary" @click="showSettings = !showSettings">
          Einstellungen
          <i class="fal" :class="[showSettings ? 'fa-xmark' : 'fa-plus']" />
        </button>
      </div>
    </div>
    <div
      v-if="showSettings"
      class="card card-custom position-absolute top-0 right-0 mt-16"
      style="z-index: 12"
    >
      <div class="card-body bg-white">
        <h5 class="text-muted mb-3">Diagramm</h5>
        <div class="mb-3">
          <label>Typ</label>
          <v-select
            v-model="chart.type"
            :items="types"
            hide-details
            class="form-control ml-1"
            :menu-props="{ offsetY: true }"
            @change="setGraphTypes"
          />
        </div>
        <div class="d-flex align-items-center mb-3">
          <label class="m-0 mr-1">Graphen übereinander</label>
          <span class="switch switch-sm">
            <label>
              <input v-model="chart.stacked" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
        <div class="d-flex align-items-center mb-3">
          <label class="m-0 mr-1">Smooth</label>
          <span class="switch switch-sm">
            <label>
              <input v-model="chart.smooth" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
        <div v-for="(seriesSet, i) in [seriesOne, seriesTwo]" :key="i">
          <div class="separator separator-solid mb-3" />
          <h5 class="text-muted mb-3">Graph {{ seriesSet.name }}</h5>
          <div class="d-flex align-items-center mb-3">
            <label class="m-0 mr-1">Graph anzeigen</label>
            <span class="switch switch-sm">
              <label>
                <input v-model="seriesSet.show" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
          <div class="mb-3">
            <label>Graph Typ</label>
            <v-select
              v-model="seriesSet.type"
              :items="seriesTypes"
              hide-details
              class="form-control"
              :menu-props="{ offsetY: true }"
              @input="setGraphType($event, seriesSet)"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="height: 75vh">
      <apexchart
        id="chart"
        :type="chart.type"
        height="100%"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      showSettings: true,
      chart: {
        type: "line",
        stacked: false,
        smooth: true
      },
      seriesOne: {
        name: "2021",
        show: true,
        type: "line",
        data: this.seriesData(20, 45)
      },
      seriesTwo: {
        name: "2020",
        show: true,
        type: "line",
        data: this.seriesData(10, 35)
      },
      types: [
        { text: "Linie", value: "line" },
        { text: "Area", value: "area" }
      ],
      seriesTypes: [
        { text: "Linie", value: "line" },
        { text: "Area", value: "area" },
        { text: "Bar", value: "column" }
      ]
    };
  },
  computed: {
    options: function () {
      return {
        xaxis: {
          categories: this.days(),
          title: {
            text: "Oktober"
          }
        },
        yaxis: {
          min: 0,
          max: 50,
          title: {
            text: "Bestellungen"
          }
        },
        legend: {
          showForSingleSeries: true
        },
        chart: {
          stacked: this.chart.stacked
        },
        stroke: {
          curve: this.chart.smooth ? "smooth" : "straight"
        },
        dataLabels: {
          enabled: false
        }
      };
    },
    series: function () {
      let series = [];
      if (this.seriesOne.show) {
        let set = this.seriesOne;
        set.type = this.seriesOne.type;
        series.push(set);
      }
      if (this.seriesTwo.show) {
        let set = this.seriesTwo;
        set.type = this.seriesTwo.type;
        series.push(set);
      }
      return series;
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.push({ name: "dashboard" });
    },
    days() {
      let days = [];
      for (let i = 1; i <= 31; i++) {
        days.push(i + ".");
      }
      return days;
    },
    seriesData(min, max) {
      console.log(min, max);
      let values = [];
      for (let i = 1; i <= 31; i++) {
        let val = Math.floor(Math.random() * (max - min)) + min;
        values.push(val);
      }
      return values;
    },
    setGraphType(type, series) {
      console.log("test");
      this.$set(series, "type", type);
      this.$forceUpdate();
    },
    setGraphTypes() {
      let type = this.chart.type;
      this.setGraphType(type, this.seriesOne);
      this.setGraphType(type, this.seriesTwo);
    }
  }
};
</script>

<style lang="scss">
#chart {
  height: 90vh;
}
</style>
